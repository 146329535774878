import {Component, Input} from '@angular/core';
import {LocationInfo} from './model/LocationInfo';
import {PollutionService} from './service/pollution-service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSelectionListChange} from '@angular/material/list';

@Component({
  selector: 'app-location-selection',
  templateUrl: './locationSelection.component.html',
  // styleUrls: ['./locationSelection.component.css']
})
export class LocationSelectionComponent {
  selected: LocationInfo;
  areas: LocationInfo[];
  constructor(pollutionService: PollutionService,
              private dialogRef: MatDialogRef<LocationSelectionComponent>) {
    pollutionService.getAreas().subscribe((x) => {
      this.areas = x;
    });
  }
  selectionChanged(e: MatSelectionListChange): void{
    this.dialogRef.close(e.options[0].value);
  }
}
