import {LocationInfo} from './LocationInfo';
import {PollutionInfo} from './PollutionInfo';

const raw = [
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T00:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 2.487483263015747,
    pm25: 1.4742305278778076,
    no: 0.024908199906349182,
    no2: 0.263287216424942,
    co: 0.14939619600772858,
    windSpeed: 3.4373390674591064,
    precipitation: 3.4373390674591064,
    temperature: 1.4014933268229395
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T03:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 6.808715343475342,
    pm25: 0.797541618347168,
    no: 0.3424720764160156,
    no2: 4.449861526489258,
    co: 2.6946609020233154,
    windSpeed: 2.2787742614746094,
    precipitation: 2.2787742614746094,
    temperature: 2.0673563639323143
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T06:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 11.129684448242188,
    pm25: 1.5547327995300293,
    no: 2.5461878776550293,
    no2: 4.880531311035156,
    co: 7.021799087524414,
    windSpeed: 1.3824352025985718,
    precipitation: 1.3824352025985718,
    temperature: 2.4183288574218977
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T09:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 3.1495158672332764,
    pm25: 1.9890860319137573,
    no: 1.5641894340515137,
    no2: 2.338284730911255,
    co: 2.479952096939087,
    windSpeed: 2.9485397338867188,
    precipitation: 2.9485397338867188,
    temperature: 4.91766764322919
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T12:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 4.250720024108887,
    pm25: 2.717332601547241,
    no: 1.4710192680358887,
    no2: 2.3443193435668945,
    co: 2.192594051361084,
    windSpeed: 3.5245587825775146,
    precipitation: 3.5245587825775146,
    temperature: 5.883935546875023
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T15:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 18.178178787231445,
    pm25: 5.387579441070557,
    no: 1.4878990650177002,
    no2: 8.160083770751953,
    co: 6.271173000335693,
    windSpeed: 3.253657341003418,
    precipitation: 3.253657341003418,
    temperature: 4.374037679036481
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T18:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 27.576698303222656,
    pm25: 7.5472187995910645,
    no: 0.9436847567558289,
    no2: 15.829575538635254,
    co: 17.922409057617188,
    windSpeed: 1.6553490161895752,
    precipitation: 1.6553490161895752,
    temperature: 3.006728108723981
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-12T21:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 5.996036052703857,
    pm25: 3.9735522270202637,
    no: 1.5787510871887207,
    no2: 7.987612724304199,
    co: 4.95530891418457,
    windSpeed: 0.7148882746696472,
    precipitation: 0.7148882746696472,
    temperature: 3.038130696614606
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T00:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 6.42354154586792,
    pm25: 4.161006450653076,
    no: 0.20396992564201355,
    no2: 1.8741519451141357,
    co: 1.0940630435943604,
    windSpeed: 0.8049588799476624,
    precipitation: 0.8049588799476624,
    temperature: 2.2472269694010643
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T03:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 20.17527961730957,
    pm25: 4.553365230560303,
    no: 0.619479775428772,
    no2: 6.452981948852539,
    co: 6.690586090087891,
    windSpeed: 1.4727213382720947,
    precipitation: 1.4727213382720947,
    temperature: 1.905562337239606
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T06:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 19.361656188964844,
    pm25: 4.973607063293457,
    no: 3.4229564666748047,
    no2: 7.054311275482178,
    co: 8.08041763305664,
    windSpeed: 1.3719966411590576,
    precipitation: 1.3719966411590576,
    temperature: 3.3544352213541893
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T09:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 7.442843437194824,
    pm25: 4.714860916137695,
    no: 1.8342570066452026,
    no2: 2.69474720954895,
    co: 2.761626958847046,
    windSpeed: 2.3541102409362793,
    precipitation: 2.3541102409362793,
    temperature: 5.871230061848981
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T12:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 6.935193061828613,
    pm25: 4.370572566986084,
    no: 1.8327733278274536,
    no2: 2.896303176879883,
    co: 2.7162270545959473,
    windSpeed: 2.509904384613037,
    precipitation: 2.509904384613037,
    temperature: 7.217563883463565
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T15:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 17.960922241210938,
    pm25: 5.390693664550781,
    no: 2.130063533782959,
    no2: 10.67801570892334,
    co: 7.378474235534668,
    windSpeed: 2.2257680892944336,
    precipitation: 2.2257680892944336,
    temperature: 6.49823404947919
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T18:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 18.835643768310547,
    pm25: 7.034145355224609,
    no: 1.6195257902145386,
    no2: 13.901254653930664,
    co: 11.268139839172363,
    windSpeed: 1.4500521421432495,
    precipitation: 1.4500521421432495,
    temperature: 4.373773193359398
  },
  {
    distance: 0.006497035895855567,
    geoHash: 'u0prh9dcv',
    time: '2020-11-13T21:00:00',
    lat: 46.239041367155,
    lon: 10.397059669345001,
    qualityIndex: 1,
    pm10: 9.816788673400879,
    pm25: 6.3811936378479,
    no: 0.7538692951202393,
    no2: 7.088761806488037,
    co: 6.6049652099609375,
    windSpeed: 0.9467993378639221,
    precipitation: 0.9467993378639221,
    temperature: 3.618636067708356
  }
];
const rawLocation = {
  city: 'Vezza d\'Oglio',
  street: 'Via Valeriana',
  zipCode: '',
  county: 'Brescia',
  state: 'Lombardy',
  country: 'Italy',
  providerName: 'LocationIQ'
};
export const TEST_RESPONSE = {
  locationInfo: LocationInfo.fromJson(rawLocation),
  pollutionInfo: raw.map(i => PollutionInfo.parse(i))
};

