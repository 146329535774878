
<mat-sidenav-container fullscreen>


  <mat-toolbar  color="primary">

    <img src="assets/bb-clean-logo-med.png">
    <div style="margin-right: 1em"></div>
    <span style="color: white">BB-CLEAN</span>

  </mat-toolbar>

  <app-location-info [pollutionInfo]="currentInfo" [locationInfo]="locationInfo" style="margin: 5px">

  </app-location-info>

  <div class="info-container">
    <div>
      <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="50" class="example-viewport">

        <!--
        <app-pollution-info *cdkVirtualFor="let item of infos" [info]="item">

        </app-pollution-info>
         -->
      </cdk-virtual-scroll-viewport>
    </div>
    <!--
    <app-pollution-info *ngFor="let info of infos" [info]="info">

    </app-pollution-info>
    -->
  </div>


</mat-sidenav-container>


