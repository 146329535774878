const IDENTIFIER_LEN = 12;
export class ClientId{
  static fromJson(json: string): ClientId {
    const parsed = JSON.parse(json);
    return new ClientId(parsed.expire, parsed.id);
  }
  static create(expireInHours: number): ClientId{
    const expires = 1000 * 60 * 60 * expireInHours;
    const id = Math.random().toString(36).substr(2, IDENTIFIER_LEN);
    return new ClientId(Date.now() + expires, id);
  }
  constructor(public expire: number, public id: string) {
  }

  isExpired(): boolean{
    return (this.expire - Date.now()) < 0;
  }

}
