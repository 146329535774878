import {Component, OnInit} from '@angular/core';
import {TEST_RESPONSE} from './model/TestData';
import {MatDialog} from '@angular/material/dialog';
import {LocationSelectionComponent} from './locationSelection.component';
import {LocalStorageService} from './service/local-storage.service';
import {PollutionService} from './service/pollution-service';
import {PollutionInfo} from './model/PollutionInfo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'bbclean-spa';
  index = 0;
  locationInfo = TEST_RESPONSE.locationInfo;
  infos = TEST_RESPONSE.pollutionInfo;
  currentInfo: PollutionInfo = PollutionInfo.empty();

  constructor(private dialog: MatDialog,
              private pollutionService: PollutionService,
              private storageService: LocalStorageService) {
  }

  ngOnInit(): void {
    const location = this.storageService.getLocation();
    if (location == null) {
      this.selectLocation();
    }
    else{
      this.setInfo();
      this.locationInfo = location;
    }
  }

  private setInfo(): void{
    const lat = this.locationInfo.lat;
    const lon = this.locationInfo.lon;
    this.pollutionService.getPollutionInfo(lat, lon).subscribe( x => {
      this.infos = x;
      this.currentInfo = this.infos[0];
    });
  }

  private selectLocation(): void{
    const dialogRef = this.dialog.open(LocationSelectionComponent, {
      height: '400px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((x) => {
        this.storageService.saveLocation(x);
        this.locationInfo = x;
        this.setInfo();
    });
  }
}
