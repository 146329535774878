export class LocationInfo{
  constructor(
  public city: string,
  public street: string,
  public zipCode: string,
  public county: string,
  public state: string,
  public country: string,
  public providerName: string,
  public lat: number,
  public lon: number){}
  static fromJson(json: any): LocationInfo{
    return new LocationInfo(
      json.city,
      json.street,
      json.zipCode,
      json.county,
      json.state,
      json.country,
      json.providerName,
      json.lat,
      json.lon
    );
  }
}
