import {Component, Input} from '@angular/core';
import {LocationInfo} from './model/LocationInfo';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {PollutionInfo} from './model/PollutionInfo';

@Component({
  selector: 'app-pollution-forecast',
  templateUrl: './pollutionForecast.component.html',
  styleUrls: ['./pollutionForecast.component.css']
})
export class PollutionForecastComponent {
  @Input() pollutionInfo: PollutionInfo[];

  displayedColumns = ['forecast', 'date', 'time'];
}
