<table style="width: 100%" mat-table [dataSource]="pollutionInfo">

  <ng-container matColumnDef="forecast">
    <th mat-header-cell *matHeaderCellDef>Forecast </th>
    <td mat-cell *matCellDef="let element">
        <app-traffic-light-horizontal style="width: 75%" [index]="element.qualityIndex">
        </app-traffic-light-horizontal>
    </td>
  </ng-container>


  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element.datePart}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef> Time </th>
    <td mat-cell *matCellDef="let element"> {{element.timePart}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
