
function pad(num, size: number): string {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

export class PollutionInfo{
  constructor(
    public distance: number,
    public time: string,
    public lat: number,
    public lon: number,
    public qualityIndex: number,
    public pm10: number,
    public pm25: number,
    public no: number,
    public no2: number,
    public co: number,
    public windSpeed: number,
    public precipitation: number,
    public temperature: number,
    public selected: boolean)
  {}

  get date(): Date{
    return new Date(this.time);
  }
  get datePart(): string{
    return `${this.date.getFullYear()}-${this.date.getMonth()}-${this.date.getDay()}`;
  }
  get timePart(): string{
    return `${pad(this.date.getHours(), 2)}:${pad(this.date.getMinutes(), 2)}`;
  }

  static empty(): PollutionInfo {
    return new PollutionInfo(0, '2020-12-17T06:00:00', 0, 0, 1,
      0, 0, 0, 0, 0, 0, 0, 0, false);
  }


  static round(num): number{
    return Math.round(num * 100) / 100;
  }


  static parse(json: any): PollutionInfo{
    return new PollutionInfo(
      json.distance,
      json.time,
      json.lat,
      json.lon,
      json.qualityIndex,
      this.round(json.pm10),
      this.round(json.pm25),
      this.round(json.no),
      this.round(json.no2),
      this.round(json.co),
      this.round(json.windSpeed),
      this.round(json.precipitation),
      this.round(json.temperature),
      false
      );
  }
}
