import {Component, Input} from '@angular/core';
import {LocationInfo} from './model/LocationInfo';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {PollutionInfo} from './model/PollutionInfo';

@Component({
  selector: 'app-pollution-info',
  templateUrl: './pollutionInfo.component.html',
  styleUrls: ['./pollutionInfo.component.css']
})
export class PollutionInfoComponent {
  @Input() info: PollutionInfo;
}
