
<mat-card>
  <mat-card-header>

      <i mat-card-avatar>
        <mat-icon style="transform: scale(2); padding: 0.5em;" svgIcon="location"></mat-icon>
      </i>

    <mat-card-title>
      {{locationInfo.city ? locationInfo.city : '-'}}
    </mat-card-title>

    <mat-card-subtitle>
      {{locationInfo.county}}, {{locationInfo.county}}
    </mat-card-subtitle>
    <div class="fill-remaining-space"></div>
    <div>
      <mat-icon style="transform: scale(2); margin-right: 1em" svgIcon="clock"></mat-icon>
      <span class="mat-display-1">12:00</span>
      </div>
  </mat-card-header>

  <mat-card-content>

    <div class="wrapper">

      <app-weather-info value="{{pollutionInfo.precipitation}}" unit="mm" icon="rain">
      </app-weather-info>

       <app-weather-info value="{{pollutionInfo.temperature}}"  unit="°C" icon="temperature">
       </app-weather-info>

       <app-weather-info value="{{pollutionInfo.windSpeed}}" unit="m/s" icon="wind">
       </app-weather-info>

       <app-weather-info value="{{pollutionInfo.pm10}}" unit="μm/m³" icon="pm10">
       </app-weather-info>

       <app-weather-info value="{{pollutionInfo.pm25}}" unit="μm/m³" icon="pm25">
       </app-weather-info>

    </div>

    <div class="wrapper">
      <app-traffic-light-horizontal [qualityIndex]="pollutionInfo.qualityIndex"  style="width: 65%" >

      </app-traffic-light-horizontal>
    </div>


  </mat-card-content>

</mat-card>
