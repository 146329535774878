import {Component, Input} from '@angular/core';
import {LocationInfo} from './model/LocationInfo';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {PollutionInfo} from './model/PollutionInfo';

@Component({
  selector: 'app-location-info',
  templateUrl: './locationInfo.component.html',
  styleUrls: ['./locationInfo.component.css']
})
export class LocationInfoComponent {
  constructor(
    private icoRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.registerIcon('clock');
    this.registerIcon('location');
    this.registerIcon('rain');
    this.registerIcon('pm10');
    this.registerIcon('pm25');
    this.registerIcon('temperature');
    this.registerIcon('wind');
  }
  @Input() locationInfo: LocationInfo;
  @Input() pollutionInfo: PollutionInfo;
  registerIcon(iconName: string): void{
     this.icoRegistry.addSvgIcon(
       iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${iconName}.svg`)
     );
  }
}
