
<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{info.date.getHours()}}
    </mat-card-title>

  </mat-card-header>

  <mat-card-content>



    <div class="wrapper">
      <app-traffic-light-horizontal style="width: 65%" index="2">

      </app-traffic-light-horizontal>
    </div>

  </mat-card-content>

</mat-card>
