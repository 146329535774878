import {Injectable} from '@angular/core';
import {LocationInfo} from '../model/LocationInfo';
import {ClientId} from '../model/ClientId';

const LOCATION_KEY = 'location';
const CLIENT_ID_KEY = 'clientId';

// in hours
const CLIENT_ID_EXPIRE_DURATION = 1000 * 60 * 60 * 24 * 7;

@Injectable()
export class LocalStorageService{
  private static generateClientId(): ClientId{
    const newClientId = ClientId.create(CLIENT_ID_EXPIRE_DURATION);
    localStorage.setItem(CLIENT_ID_KEY, JSON.stringify(newClientId));
    return newClientId;
  }
  hasSelectedLocation(): boolean{
    return localStorage.getItem(LOCATION_KEY) != null;
  }
  getClientId(): ClientId{
    const json = localStorage.getItem(CLIENT_ID_KEY);
    if (!json){
      return LocalStorageService.generateClientId();
    }
    else{
      const clientId = ClientId.fromJson(json);
      if (clientId.isExpired()){
        return LocalStorageService.generateClientId();
      }
      return clientId;
    }
  }
  getLocation(): LocationInfo {
    const item = localStorage.getItem(LOCATION_KEY);
    if (item){
      return null;
    }
    return JSON.parse(item);
  }
  saveLocation(loc: LocationInfo): void{
    localStorage.setItem(LOCATION_KEY, JSON.stringify(loc));
  }
}
