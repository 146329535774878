import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LocationInfo} from '../model/LocationInfo';
import {LocalStorageService} from './local-storage.service';
import {PollutionInfo} from '../model/PollutionInfo';
import {map} from 'rxjs/operators';

const BASE_URL = 'https://miass.fh-joanneum.at/bbclean/api/v1/';
@Injectable()
export class PollutionService{
  private headers: HttpHeaders;
  constructor(private client: HttpClient,
              private storageService: LocalStorageService) {
    this.headers = new HttpHeaders().set('x-api-key', 'qoRsPRZ5V8WxPdN7J6myXor7');

  }
  getAreas(): Observable<LocationInfo[]>{
    return this.client.get<LocationInfo[]>(BASE_URL + 'areas', {
      headers: this.headers
    });
  }
  getPollutionInfo(lat: number, lon: number): Observable<Array<PollutionInfo>>{
    const clientId = this.storageService.getClientId();
    return this.client.get<any>(`${BASE_URL}pollution/${lat},${lon}?clientId=${clientId.id}`, {
      headers: this.headers
    }).pipe(map(x => x.pollutionInfo.map(p => PollutionInfo.parse(p))));
  }
}
