import {Component, Input} from '@angular/core';
import {LocationInfo} from './model/LocationInfo';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-traffic-light-horizontal',
  templateUrl: './trafficLightHorizontal.component.html',
  styleUrls: ['./trafficLightHorizontal.component.css']
})
export class TrafficLightHorizontalComponent {
  @Input() qualityIndex: number;

  get filename(): string{
    switch ((this.qualityIndex - 1).toString()) {
      case '0':   return 'tl_green';
      case '1':   return 'tl_yellow';
      case '2':   return 'tl_red';
      default:  return 'tl_none';
    }
  }


}
