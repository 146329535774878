import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {MatToolbarModule} from '@angular/material/toolbar';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {LocationInfoComponent} from './locationInfo.component';
import {MatCardModule} from '@angular/material/card';
import {HttpClientModule} from '@angular/common/http';
import {WeatherInfoComponent} from './weatherInfo.component';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {TrafficLightHorizontalComponent} from './trafficLightHorizontal.component';
import {PollutionForecastComponent} from './pollutionForecast.component';
import {MatTable, MatTableModule} from '@angular/material/table';
import {MatListModule, MatSelectionList} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {LocationSelectionComponent} from './locationSelection.component';
import {MatDialogModule} from '@angular/material/dialog';
import {LocalStorageService} from './service/local-storage.service';
import {PollutionService} from './service/pollution-service';
import {PollutionInfoComponent} from './pollutionInfo.component';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    AppComponent,
    LocationInfoComponent,
    WeatherInfoComponent,
    TrafficLightHorizontalComponent,
    PollutionForecastComponent,
    LocationSelectionComponent,
    PollutionInfoComponent
  ],
  imports: [
    ScrollingModule,
    MatListModule,
    MatTableModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    HttpClientModule,
    MatSidenavModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [LocalStorageService, PollutionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
