import {Component, Input} from '@angular/core';
import {LocationInfo} from './model/LocationInfo';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-weather-info',
  templateUrl: './weatherInfo.component.html',
  styleUrls: ['./weatherInfo.component.css']
})
export class WeatherInfoComponent {
  @Input() unit: string;
  @Input() value: string;
  @Input() icon: string;
}
